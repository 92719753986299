<div #calendar class="relative calendar" [class.calendar-open]="(open$ | async) || false" (focusin)="onFocusIn()" (focusout)="onFocusOut($event)" tabindex="0">
    <div>
        <app-preview [placeholder]="placeholder" [open]="(open$ | async)!" [value]="(selectedValue$ | async) || null" />

        <div class="dropdown" @Grow *ngIf="(open$ | async) === true">
            <app-picker
                [startDate]="startDate"
                [endDate]="endDate"
                [disabledDates]="disabledDates"
                [selectedDate]="value"
                (selectDateEvent)="selectDateEvent($event)"
            />
            <div class="w-[50%] mx-auto">
                <app-reset (resetEvent)="resetEvent()" />
            </div>
        </div>
    </div>
</div>
