import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '@app/store/models';

export const productActions = createActionGroup({
    source: 'Product',
    events: {
        'Get Product Details': props<{ id: string }>(),
        'Get Product Details Success': props<{ product: Product }>(),
        'Get Product Details Error': emptyProps(),

        'Get Product Description': props<{ productId: string }>(),
        'Get Product Description Success': props<{ description: string }>(),
        'Get Product Description Error': emptyProps(),

        'Open Product Card': props<{ productNo: string }>(),
        'Open Product Card Success': props<{ blob: Blob }>(),
        'Open Product Card Error': emptyProps(),

        Clear: emptyProps(),
    },
});
