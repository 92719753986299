<div class="header-container">
    <header class="mx-5 | 3xl:container 3xl:mx-auto">
        <div>
            <app-logo />
        </div>

        <div *ngIf="xl$ | async" class="menu">
            <app-menu [menu]="menu" />
        </div>

        <div class="ml-auto border-padding-box" *ngIf="md$ | async">
            <app-language-switch />
        </div>

        <div class="shipping border-padding-box ml-auto lg:ml-0">
            <app-shipping-addresses />
        </div>

        <div *ngIf="md$ | async" class="cart border-padding-box">
            <app-cart-summary />
        </div>

        <div *ngIf="md$ | async" class="border-padding-box">
            <app-profile type="user" />
        </div>

        <div *ngIf="lg$ | async" class="border-padding-box">
            <app-switch-user *ngIf="(userSwitchEnabled$ | async) === true" />
            <app-logout *ngIf="(userSwitchEnabled$ | async) === false" />
        </div>

        <div class="border-padding-box" *ngIf="(xl$ | async) === false">
            <app-mobile-menu-button>
                <app-cart-summary-mobile />

                <app-menu-mobile [menu]="menu" />

                <div class="bottom-10 left-10 absolute">
                    <app-language-switch />
                </div>
            </app-mobile-menu-button>
        </div>
    </header>
</div>
