<div class="px-6 py-3">
    <div class="nav grid grid-cols-4 items-center">
        <button (click)="onPrevMonthClick($event)" class="next me-2">
            <app-icon name="material-symbols:arrow-left-alt" class="text-md leading-4"></app-icon>
        </button>
        <div class="text-sm text-gray-700 font-semibold text-center">{{ 'calendar.month.' + (currentDate$ | async)!.getMonth() | translate }}</div>
        <div class="text-sm text-gray-700 font-semibold text-center">{{ (currentDate$ | async)?.getFullYear() || '-' }}</div>
        <button (click)="onNextMonthClick($event)" class="prev ms-2">
            <app-icon name="material-symbols:arrow-right-alt" class="text-md leading-4"></app-icon>
        </button>
    </div>

    <div class="grid grid-cols-7 gap-y-1">
        <div class="header" *ngFor="let _ of [].constructor(7); let no = index">{{ 'calendar.weekday.' + no | translate }}</div>

        <div
            class="text-center date"
            *ngFor="let date of calendar$ | async"
            [ngClass]="{
                disabled: date | disabledDate: (startDate$ | async)! : (endDate$ | async)! : (disabledDates$ | async)!,
                today: date | todayDate,
                before: date | beforeCurrentDate: (selectedDates$ | async),
                after: date | afterCurrentDate: (selectedDates$ | async),
                first: date | firstSelectedDate: (selectedDates$ | async),
                last: date | lastSelectedDate: (selectedDates$ | async),
                'current-month': date | currentMonth: (currentDate$ | async)
            }"
        >
            <button
                (click)="selectDate($event, date)"
                [attr.disabled]="(date | disabledDate: (startDate$ | async)! : (endDate$ | async)! : (disabledDates$ | async)!) === true ? true : null"
            >
                {{ date.getDate() }}
            </button>
        </div>
    </div>
</div>
