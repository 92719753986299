<div [attr.data-test-id]="controlName">
    <div class="relative text-xs md:text-sm">
        <textarea [ngClass]="class" [formControl]="control"></textarea>

        <app-floating-label
            [placeholder]="placeholder ?? ''"
            [value]="control.value"
            [displayValue]="false"
            defaultStateClass="!top-3 !translate-y-0"
        ></app-floating-label>
    </div>

    <app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>
</div>
