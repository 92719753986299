<div #dropdown class="dropdown" [class.dropdown-open]="(open$ | async) || false" (focusin)="onFocusIn()" (focusout)="onFocusOut($event)" tabindex="1">
    <div>
        <app-preview [placeholder]="placeholder" [options]="options" [value]="control.getRawValue()" />

        <div class="list" @Grow *ngIf="(open$ | async) === true">
            <app-list [options]="options" [value]="control.getRawValue()" (selectEvent)="onSelectEvent($event)" />
            <div class="loader" *ngIf="loading">
                <div>
                    <app-spinner />
                </div>
            </div>
        </div>
    </div>
</div>
