import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as XRegExp from 'xregexp';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const NipValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern = "^[\\p{N}\\s\\-']+$";

    if (value.trim() === '' && value !== '') {
        return { [FormErrorTypes.NIP]: true };
    }

    if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
        return { [FormErrorTypes.NIP]: true };
    }

    if (value.length !== 10 && value !== '') {
        return { [FormErrorTypes.NIP]: true };
    }

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const numbers = value.split('');

    const partialSums = weights.map((weight, index) => {
        return weight * parseInt(numbers[index], 10);
    });

    const sum = partialSums.reduce((prev, curr) => (curr += prev), 0);
    const controlNumber = sum % 11;

    if (parseInt(numbers[9], 10) !== controlNumber && value !== '') {
        return { [FormErrorTypes.NIP]: true };
    }

    return null;
};
