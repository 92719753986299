import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductAmountPipe } from './pipes/product-amount.pipe';

@NgModule({
    declarations: [ProductAmountPipe],
    imports: [CommonModule],
    exports: [ProductAmountPipe],
})
export class ProductAmountModule {}
