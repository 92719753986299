import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { environment } from '@env/environment';

export function panelGuard(): CanActivateFn {
    return () => {
        const router = inject(Router);
        if (environment.isAdmin === true) {
            return router.createUrlTree(['/admin']);
        }
        return true;
    };
}
