import { Component } from '@angular/core';
import { footerEnvironment } from '@env/footer.environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    readonly footerLinks = footerEnvironment.footerLinks;
    readonly shopLinks = footerEnvironment.shopLinks;
    readonly contact = footerEnvironment.contact;
}
