<div class="px-2 py-1 text-xs">
    <ng-container *ngIf="options.length; else noData">
        <div @Grow *ngFor="let option of options; trackBy: trackOptionBy">
            <div class="py-0.5">
                <button (click)="selectOption($event, option.value)" [class.selected]="option.value === value">
                    {{ option.name }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-template #noData>
        <div class="p-2" translate>table.no-content</div>
    </ng-template>
</div>
