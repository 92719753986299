<div class="flex items-center w-min border border-eip-border h-[2.5rem] px-2 rounded-eip-cta relative bg-white font-light">
    <button (click)="removeOne()" class="disabled:text-gray-400 text-lg px-1.5" [disabled]="this.input.getRawValue() === 1">-</button>
    <input
        type="text"
        [formControl]="input"
        (keypress)="onKeypress($event)"
        (paste)="onPaste($event)"
        (blur)="onBlur()"
        (focus)="onFocus()"
        class="w-12 text-center bg-transparent"
    />
    <span class="absolute pointer-events-none text-gray-500 text-xs md:text-sm floating-state">
        {{ placeholder ?? ('quantity' | translate) }}
    </span>
    <button (click)="addOne()" class="disabled:text-gray-400 px-1.5 text-lg" [disabled]="+this.input.getRawValue() === MAX_VALUE">+</button>
</div>
