interface ProductDto {
    item_id: string;
    item_no: string;
    item_no2: string;
    item_description: string;
    item_description2: string;
    item_category_code: string;
    item_manufacturer_code: string;
    item_vat_rate: number;
    item_discount: number;
    inventory_on_stock: number;
    image_url: string;
    item_promo: boolean;
    favorite: boolean;
    item_base_uo_m: string;
    item_price: number;
    sales_price: number;
    gtin: string;
}

export { ProductDto };
