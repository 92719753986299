import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { selectLoaded, selectProductDescription, selectProductDetails } from '../selectors/product.selector';
import { productActions } from '../actions/product.actions';
import { Product } from '@app/store/models';

@Injectable({
    providedIn: 'root',
})
export class ProductFacade {
    private store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    productDetails$ = this.store.select(selectProductDetails).pipe(filter((data): data is Product => data !== null));
    productDescription$ = this.store.select(selectProductDescription).pipe(filter((data): data is string => data !== null));

    get productDetails(): Product | null {
        let product = null;
        this.store
            .select(selectProductDetails)
            .pipe(
                take(1),
                tap((item) => (product = item)),
            )
            .subscribe();
        return product;
    }

    getProductDetails(id: string): void {
        this.store.dispatch(productActions.getProductDetails({ id }));
    }

    getProductDescription(productId: string): void {
        this.store.dispatch(productActions.getProductDescription({ productId }));
    }

    openProductCard(productNo: string): void {
        this.store.dispatch(productActions.openProductCard({ productNo }));
    }

    clear(): void {
        this.store.dispatch(productActions.clear());
    }
}
