import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-input',
    styleUrls: ['./form-input.component.scss'],
    templateUrl: './form-input.component.html',
})
export class FormInputComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() iconName: string | undefined;
    @Input() placeholder?: string;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() inputClasses = '';

    control!: FormControl<string | null>;

    ngOnInit() {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    get value() {
        return this.control.getRawValue();
    }
}
