<div [attr.data-test-id]="controlName">
    <div class="relative min-w-[16rem]">
        <app-calendar
            [formControl]="control"
            type="text"
            [placeholder]="placeholder ?? ''"
            [startDate]="startDate"
            [endDate]="endDate"
            [disabledDates]="disabledDates"
        />
    </div>
    <app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>
</div>
