import { UserBlockedDto } from '@app/store/models';

interface CustomerDto {
    no: string;
    name: string;
    balance: string;
    balance_due: string;
    credit_limit_lcy: string;
    sales_person_code: string;
    location_code: string;
    blocked: UserBlockedDto;
    vat_registration_no: string;
    post_code: string;
    city: string;
    search_name: string;
    addresses: string;
    payment_terms: string;
    payment_method: string;
}

export { CustomerDto };
