import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const DateRangeValidator = (startDateControlName: string, endDateControlName: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const firstControl = control.get(startDateControlName);
        const secondControl = control.get(endDateControlName);

        if (firstControl === null || secondControl === null || !(firstControl.value instanceof Date) || !(secondControl.value instanceof Date)) {
            return null;
        }

        if (firstControl.value.getTime() > secondControl.value.getTime()) {
            return { [FormErrorTypes.INVALID_DATE_RANGE]: true };
        }

        return null;
    };
};
