import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as XRegExp from 'xregexp';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const PhoneValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = typeof control.value === 'string' ? control.value : '';
    if (value === '') {
        return null;
    }
    const pattern = /^[+]?\d+$/;

    if (value.trim() !== value || value.trim() === '' || (!XRegExp.test(value.trim(), XRegExp(pattern)) && value !== '')) {
        return { [FormErrorTypes.WRONG_PHONE_NUMBER]: true };
    }

    return null;
};
