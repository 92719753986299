import { Component, ElementRef, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

import { Option } from '../../models/option.model';
import { Grow } from '@app/shared/animations';
import { DropdownService } from '../../service/dropdown.service';

@Component({
    selector: 'app-form-dropdown-tiny',
    templateUrl: './form-dropdown-tiny.component.html',
    styleUrls: ['./form-dropdown-tiny.component.scss'],
    animations: [Grow],
    providers: [DropdownService],
})
export class FormDropdownTinyComponent implements OnInit {
    @ViewChild('dropdown') dropdown!: ElementRef<HTMLDivElement>;
    private readonly controlContainer = inject(ControlContainer);
    private readonly dropdownService = inject(DropdownService);

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) options!: Option[];
    @Input() loading: boolean = false;
    @Input() placeholder?: string;

    open$ = this.dropdownService.open$;
    @Output() openEvent = this.open$;

    control!: FormControl<string | null>;

    ngOnInit() {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    onSelectEvent(value: string | null) {
        this.control.patchValue(value);
        this.control.markAsTouched();
        this.open$.next(false);
        this.dropdown.nativeElement.blur();
    }

    onFocusIn() {
        this.open$.next(true);
    }

    onFocusOut(event: FocusEvent) {
        const target = <HTMLElement>event.relatedTarget;
        if (this.dropdown.nativeElement.contains(target) === false) {
            this.open$.next(false);
        }
    }
}
