import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currentMonth',
})
export class CurrentMonthPipe implements PipeTransform {
    transform(date: Date, selectedDate: Date | null): boolean {
        if (selectedDate === null) {
            return false;
        }
        return selectedDate.getMonth() === date.getMonth();
    }
}
