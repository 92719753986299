import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstSelectedDate',
})
export class FirstSelectedPipe implements PipeTransform {
    transform(date: Date, selectedDate: [Date, Date] | [Date, null] | null): boolean {
        if (selectedDate === null) {
            return false;
        }

        if (selectedDate[1] === null) {
            return selectedDate[0].getTime() === date.getTime();
        }

        if (selectedDate[0] < selectedDate[1]) {
            return selectedDate[0].getTime() === date.getTime();
        }

        return selectedDate[1].getTime() === date.getTime();
    }
}
