import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BasePickerComponent } from '../../../shared/form-calendar-picker/picker.component';
import { truncateTime } from '../../../shared/utils/truncate-time';
import { FilterWrapperService } from '@app/shared/components';

@Component({
    selector: 'app-picker',
    styleUrls: ['./picker.component.scss'],
    templateUrl: './picker.component.html',
})
export class PickerComponent extends BasePickerComponent implements OnChanges, OnDestroy {
    private filterWrapperService = inject(FilterWrapperService);

    @Input() selectedDate: Date | null = null;
    @Output() selectDateEvent = new EventEmitter<Date>();

    selectedDate$ = new BehaviorSubject<Date | null>(null);

    ngOnChanges(changes: SimpleChanges): void {
        Object.keys(changes).forEach((key) => {
            switch (key) {
                case 'selectedDate': {
                    const date: Date | null = changes[key].currentValue ? truncateTime(changes[key].currentValue) : truncateTime(new Date());
                    this.selectedDate$.next(date);
                    if (this.currentDate$.value === null) {
                        this.currentDate$.next(date);
                    }
                    break;
                }
                case 'startDate': {
                    const date: Date | null = changes[key].currentValue ? truncateTime(changes[key].currentValue) : null;
                    this.startDate$.next(date);
                    break;
                }
                case 'endDate': {
                    const date: Date | null = changes[key].currentValue ? truncateTime(changes[key].currentValue) : null;
                    this.endDate$.next(date);
                    break;
                }
                case 'disabledDates': {
                    const dates: Date[] = changes[key].currentValue.map((date: Date) => truncateTime(date));
                    this.disabledDates$.next(dates);
                    break;
                }
            }
        });
    }

    selectDate(event: Event, date: Date) {
        event.preventDefault();
        this.selectDateEvent.next(date);
        this.filterWrapperService.close$.next(true);
    }
}
