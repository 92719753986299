import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Grow } from '@app/shared/animations';
import { truncateTime } from '../../../../forms/shared/utils/truncate-time';
import { onChangeCallback, onTouchCallback } from '@app/shared/forms/shared';

@Component({
    selector: 'app-calendar',
    styleUrls: ['./calendar.component.scss'],
    templateUrl: './calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
    ],
    animations: [Grow],
})
export class CalendarComponent implements ControlValueAccessor, Validator, OnDestroy {
    private cdr = inject(ChangeDetectorRef);

    @ViewChild('calendar') calendar!: ElementRef<HTMLDivElement>;

    @Input() label?: string;
    @Input() placeholder?: string;
    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() disabledDates: Date[] = [];

    selectedValue$ = new BehaviorSubject<[Date, Date] | [Date, null] | null>(null);
    private _value: Date | null = null;
    private subscriptions$ = new Subscription();

    get value(): Date | null {
        return this._value;
    }

    set value(value: Date | null) {
        this._value = value !== null ? truncateTime(value) : null;
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    writeValue(value?: Date): void {
        if (value) {
            this.value = value;
            this.cdr.markForCheck();
        }
    }

    registerOnChange(fn: onChangeCallback<Date | null>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: onTouchCallback): void {
        this.onTouch = fn;
    }

    onChange: onChangeCallback<Date | null> = (): void => {};
    onTouch: onTouchCallback = (): void => {};

    selectDateEvent(value: Date) {
        this.value = value;
        this.onChange(value);
        this.onTouch();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.getRawValue() !== null) {
            const dates = this.disabledDates.map((date) => truncateTime(date));
            const isInDisabled = dates.findIndex((disableDate) => disableDate.getTime() === truncateTime(control.getRawValue()).getTime()) !== -1;
            const isBefore = this.startDate ? truncateTime(control.getRawValue()) < truncateTime(this.startDate) : false;
            const isAfter = this.endDate ? truncateTime(control.getRawValue()) > truncateTime(this.endDate) : false;

            if (isInDisabled || isBefore || isAfter) {
                return { 'out-of-range': true };
            }
        }
        return null;
    }
}
