import { Injectable, inject } from '@angular/core';
import { mergeMap, from, map, catchError, of, filter } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { productsActions } from '../../products/actions/products.actions';
import { productPricingActions } from '../actions/product-pricing.actions';
import { favouritesActions } from '../../favourites/actions/favourites.actions';
import { HttpService } from '../services/http.service';
import { productSubstitutesActions } from '../../product-substitutes/actions/product-substitutes.actions';
import { selectItemLoading } from '../selectors/product-pricing.selector';
import { productActions } from '@app/store/product/actions/product.actions';

@Injectable()
export class ProductPricingEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly store = inject(Store);

    productDetailsPricingsTrigger$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productActions.getProductDetailsSuccess),
            map(({ product }) => productPricingActions.setProductPricings({ product })),
        );
    });

    setProductPricingsTriggers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productsActions.getProductsParamsSuccess, favouritesActions.getProductsSuccess, productSubstitutesActions.getProductsSuccess),
            mergeMap(({ items }) => from(items.map((item) => productPricingActions.setProductPricings({ product: item })))),
        );
    });

    getProductPricings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productPricingActions.getProductPricings),
            concatLatestFrom(({ id, no }) => this.store.select(selectItemLoading(id, no)).pipe(filter((loading) => loading === false))),
            map(([{ id, no }]) => productPricingActions.getProductPricings({ id, no })),
        );
    });

    getProductPricingsCallback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productPricingActions.getProductPricings),
            mergeMap(({ id, no }) =>
                this.httpService.getProductPricing(id).pipe(
                    map((items) => productPricingActions.getProductPricingsSuccess({ id, no, items })),
                    catchError(() => of(productPricingActions.getProductPricingsError({ id, no }))),
                ),
            ),
        );
    });
}
