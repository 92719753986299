import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'afterCurrentDate',
})
export class AfterCurrentPipe implements PipeTransform {
    transform(date: Date, selectedDate: [Date, Date] | [Date, null] | null): boolean {
        if (selectedDate === null) {
            return true;
        }

        if (selectedDate[1] === null) {
            return selectedDate[0] < date;
        }

        if (selectedDate[0] > selectedDate[1]) {
            return selectedDate[0] < date;
        }

        return selectedDate[1] < date;
    }
}
