import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSwitchComponent } from './components/form-switch/form-switch.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [FormSwitchComponent],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [FormSwitchComponent, ReactiveFormsModule],
})
export class FormSwitchModule {}
