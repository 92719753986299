export const menu = [
    {
        name: 'header.nav.dashboard',
        route: '/dashboard',
    },
    {
        name: 'header.nav.products',
        route: '/products',
    },
    {
        name: 'header.nav.documents',
        route: '/documents',
    },
    // {
    //     name: 'header.nav.categories',
    //     route: '/categories',
    // },
];
