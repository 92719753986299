import { Component, Input, inject } from '@angular/core';
import { DropdownService } from '../../service/dropdown.service';
import { Option } from '../../models/option.model';

@Component({
    selector: 'app-preview',
    styleUrls: ['./preview.component.scss'],
    templateUrl: './preview.component.html',
})
export class PreviewComponent {
    @Input({ required: true }) value: string | null = null;
    @Input({ required: true }) options: Option[] = [];
    @Input() placeholder?: string;

    private readonly dropdownService = inject(DropdownService);

    readonly open$ = this.dropdownService.open$;

    get name() {
        return this.options.find((option) => option.value === this.value)?.name;
    }
}
