interface ShippingAddressDto {
    customer_no: string;
    code: string;
    name: string;
    name2: string;
    city: string;
    country_region_code: string;
    post_code: string;
    address: string;
    address2: string;
    location_code: string;
    e_mail: string;
    shipment_method_code: string;
    system_id: string;
}

export { ShippingAddressDto };
