import { UserBlockedDto } from '@app/store/models';

type Role = 'ROLE_SALESPERSON' | 'ROLE_USER' | 'ROLE_USER_LIMITED';

interface UserDto {
    id: string;
    name: string;
    roles: Role[];
    salesperson?: UserSalespersonDto;
    salesperson_info?: UserSalespersonInfoDto;
}

interface UserSalespersonDto {
    code: string;
    email: string;
    name: string;
    role: string;
    location_code: string;
    location_filter: string[];
    image: string;
}

interface UserSalespersonInfoDto {
    no: string;
    name: string;
    name2: string;
    balance: string;
    balance_due: string;
    credit_limit_lcy: string;
    sales_person_code: string;
    location_code: string;
    blocked: UserBlockedDto;
    restaurant_name: string;
    vat_registration_no: string;
    post_code: string;
    city: string;
    addresses: string;
    payment_terms: string;
    payment_method: string;
    skip_credit_limit_verification: boolean;
}

export { Role, UserDto, UserSalespersonDto, UserSalespersonInfoDto };
