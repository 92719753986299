import { Component, inject } from '@angular/core';
import { ShowHide } from '@app/shared/animations';
import { ShoppingCartFacade } from '@app/store/shopping-cart';

@Component({
    selector: 'app-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrls: ['./cart-summary.component.scss'],
    animations: [ShowHide],
})
export class CartSummaryComponent {
    private readonly shoppingCartFacade = inject(ShoppingCartFacade);

    cartPreviewQty$ = this.shoppingCartFacade.cartPreviewQty$;
    cartProcessing$ = this.shoppingCartFacade.cartProcessing$;
    cart$ = this.shoppingCartFacade.cart$;
}
