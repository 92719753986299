import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'afterCurrentDate',
})
export class AfterCurrentPipe implements PipeTransform {
    transform(date: Date, selectedDate: Date | null): boolean {
        if (selectedDate === null) {
            return true;
        }
        return selectedDate > date;
    }
}
