<app-filter-wrapper [placeholder]="placeholder" [selectedValue]="valueLabel" iconName="healthicons:bills">
    <div [formGroup]="form" class="px-4 pb-5">
        <div class="multi-range w-full">
            <input id="min" type="range" [min]="min" [max]="max" step="1" formControlName="min" [value]="form.controls.min.value" (input)="handleMinInput()" />
            <input id="max" type="range" [min]="min" [max]="max" step="1" formControlName="max" [value]="form.controls.max.value" (input)="handleMaxInput()" />

            <div class="slider" [style.left]="left" [style.width]="width"></div>
        </div>

        <div class="grid grid-cols-2 gap-5 mt-1.5">
            <div class="input-wrapper" [attr.data-placeholder]="'forms.price-range.from' | translate">
                <input type="number" [min]="min" [max]="max" formControlName="min" [value]="form.controls.min.value" (input)="handleMinInputChange($event)" />
            </div>

            <div class="input-wrapper" [attr.data-placeholder]="'forms.price-range.to' | translate">
                <input type="number" [min]="min" [max]="max" formControlName="max" [value]="form.controls.max.value" (input)="handleMaxInputChange($event)" />
            </div>
        </div>
    </div>
</app-filter-wrapper>
