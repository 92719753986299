import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormInputComponent } from './form-input/form-input.component';
import { FormErrorModule } from './form-error/form-error.module';
import { FormInputPasswordComponent } from './form-input-password/form-input-password.component';
import { IconsModule } from '../icons/icons.module';
import { FormPriceRangeComponent } from './form-price-range/form-price-range.component';
import { FilterWrapperModule } from '../components/filter-wrapper/filter-wrapper.module';
import { FormSearchComponent } from './form-search/form-search.component';
import { FloatingLabelModule } from './floating-label/floating-label.module';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';

@NgModule({
    declarations: [
        FormInputComponent,
        FormInputPasswordComponent,
        FormPriceRangeComponent,
        FormPriceRangeComponent,
        FormSearchComponent,
        FormCheckboxComponent,
    ],
    imports: [ReactiveFormsModule, FormsModule, CommonModule, TranslateModule, FormErrorModule, IconsModule, FilterWrapperModule, FloatingLabelModule],
    exports: [ReactiveFormsModule, FormInputComponent, FormInputPasswordComponent, FormPriceRangeComponent, FormSearchComponent, FormCheckboxComponent],
})
export class AppFormsModule {}
