import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-daterange',
    styleUrls: ['./form-daterange.component.scss'],
    templateUrl: './form-daterange.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDaterangeComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() placeholder?: string;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() disabledDates: Date[] = [];

    control!: FormControl<[Date, Date] | null>;

    ngOnInit() {
        this.control = <FormControl<[Date, Date] | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }
}
