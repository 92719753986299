import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Option } from '../../models/option.model';
import { Grow } from '../../animations/grow.animation';
import { DropdownService } from '../../service/dropdown.service';
import { isStringArray } from '../../utils/is-string-array';

@Component({
    selector: 'app-form-dropdown',
    templateUrl: './form-dropdown.component.html',
    styleUrls: ['./form-dropdown.component.scss'],
    animations: [Grow],
    providers: [DropdownService],
})
export class FormDropdownComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('dropdown') dropdown!: ElementRef<HTMLDivElement>;

    private readonly controlContainer = inject(ControlContainer);
    private readonly dropdownService = inject(DropdownService);

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) options!: Option[];
    @Input() placeholder?: string;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() class = '';
    @Input() iconName?: string;
    @Input() search: boolean = false;
    @Input() lazyLoading: boolean = false;
    @Input() pageLoading: boolean = false;
    @Input() loading: boolean = false;
    @Input() canLoadMore: boolean = false;

    @Output() loadMoreOptions = new EventEmitter();
    @Output() searchChange = this.dropdownService.searchValue$;

    filteredOptions$ = this.dropdownService.filteredOptions$;
    options$ = this.dropdownService.options$;
    open$ = this.dropdownService.open$;

    control!: FormControl<string | string[] | null>;

    readonly emptyValue = '';
    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.keys(changes).forEach((key) => {
            switch (key) {
                case 'options': {
                    this.dropdownService.setOptions(changes[key].currentValue);
                    break;
                }
            }
        });
    }

    onSelectEvent(value: string | null) {
        if (isStringArray(this.control.value) && value !== null) {
            if (this.control.value.includes(value)) {
                this.control.patchValue(this.control.value.filter((curr) => curr !== value));
                this.control.markAsTouched();
                return;
            }

            this.control.patchValue([...this.control.value, value]);
            this.control.markAsTouched();
            return;
        }

        this.control.patchValue(value);
        this.control.markAsTouched();
        this.open$.next(false);
        this.dropdown.nativeElement.blur();
    }

    onFocusIn() {
        this.open$.next(true);
    }

    onFocusOut(event: FocusEvent) {
        const target = <HTMLElement>event.relatedTarget;
        if (this.dropdown.nativeElement.contains(target) === false) {
            this.open$.next(false);
        }
    }
}
