import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { BehaviorSubject, Subscription, distinctUntilChanged, filter, map, repeat, startWith, takeUntil, tap } from 'rxjs';
import * as XRegExp from 'xregexp';

@Component({
    selector: 'app-form-quantity-input',
    templateUrl: './form-quantity-input.component.html',
    styleUrls: ['./form-quantity-input.component.scss'],
})
export class FormQuantityInputComponent implements OnInit, OnDestroy {
    private fb = inject(NonNullableFormBuilder);
    private controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() placeholder?: string;

    readonly MAX_VALUE = 10000;

    input = this.fb.control<number | string>(0);
    control!: FormControl<number>;

    private focus$ = new BehaviorSubject(false);
    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.control = <FormControl<number>>(<FormGroup>this.controlContainer.control).controls[this.controlName];

        this.input.patchValue(this.control.getRawValue());

        this.subscriptions$.add(
            this.control.valueChanges
                .pipe(
                    map(() => Number(this.control.getRawValue())),
                    startWith(Number(this.control.getRawValue())),
                    distinctUntilChanged(),
                    tap((value) => this.input.patchValue(value)),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.input.valueChanges
                .pipe(
                    map(() => Number(this.input.getRawValue())),
                    distinctUntilChanged(),
                    tap((value) => this.control.patchValue(Number(value))),
                    takeUntil(this.focus$.pipe(filter((focus) => focus === true))),
                    repeat({ delay: () => this.focus$.pipe(filter((focus) => focus === false)) }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    removeOne() {
        const value = Number(this.input.getRawValue());
        if (value > 1) {
            this.input.patchValue(value - 1);
        }
    }

    addOne() {
        const value = Number(this.input.getRawValue());
        if (value < this.MAX_VALUE) {
            this.input.patchValue(value + 1);
        }
    }

    onFocus() {
        this.focus$.next(true);
    }

    onBlur() {
        this.focus$.next(false);
        let value = Number(this.input.getRawValue());

        if (value > this.MAX_VALUE) {
            value = this.MAX_VALUE;
        }

        if (value < 1) {
            value = 1;
        }

        if (this.input.getRawValue() !== value) {
            this.input.patchValue(value);
        }
    }

    onKeypress(event: KeyboardEvent) {
        const pattern = '^[0-9]+$';
        const isValid = XRegExp.test(event.key, XRegExp(pattern));

        if (!isValid) {
            event.preventDefault();
            return;
        }
    }

    onPaste(event: ClipboardEvent) {
        const pattern = '^[0-9]+$';
        const isValid = XRegExp.test(event.clipboardData?.getData('Text') ?? '', XRegExp(pattern));

        if (!isValid) {
            event.preventDefault();
            return;
        }
    }
}
