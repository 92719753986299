import { ProductCategoryDto } from './index';

export class ProductCategory {
    code: string;
    parentCategory: string;
    description: string;
    id: string;

    constructor(productCategoryDto: ProductCategoryDto) {
        this.code = productCategoryDto.code;
        this.parentCategory = productCategoryDto.parent_category;
        this.description = productCategoryDto.description;
        this.id = productCategoryDto.id;
    }
}
