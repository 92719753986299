import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingLabelComponent } from './floating-label.component';
import { IconsModule } from '@app/shared/icons';

@NgModule({
    declarations: [FloatingLabelComponent],
    imports: [CommonModule, IconsModule],
    exports: [FloatingLabelComponent],
})
export class FloatingLabelModule {}
