import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'todayDate',
})
export class TodayDatePipe implements PipeTransform {
    transform(date: Date): boolean {
        const today = new Date();
        return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
    }
}
