<div class="px-6 py-3">
    <div class="nav grid grid-cols-4 items-center">
        <button (click)="onPrevMonthClick($event)" class="next me-2">
            <app-icon name="material-symbols:arrow-left-alt" class="text-md leading-4"></app-icon>
        </button>
        <div class="text-sm text-gray-700 font-semibold text-center">{{ 'calendar.month.' + (currentDate$ | async)!.getMonth() | translate }}</div>
        <div class="text-sm text-gray-700 font-semibold text-center">{{ (currentDate$ | async)?.getFullYear() || '-' }}</div>
        <button (click)="onNextMonthClick($event)" class="prev ms-2">
            <app-icon name="material-symbols:arrow-right-alt" class="text-md leading-4"></app-icon>
        </button>
    </div>

    <div class="grid grid-cols-7 gap-y-1">
        <div class="header" *ngFor="let _ of [].constructor(7); let no = index">{{ 'calendar.weekday.' + no | translate }}</div>

        <div class="text-center" *ngFor="let date of calendar$ | async">
            <button
                class="date"
                (click)="selectDate($event, date)"
                [attr.disabled]="(date | disabledDate: (startDate$ | async)! : (endDate$ | async)! : (disabledDates$ | async)!) === true ? true : null"
                [ngClass]="{
                    disabled: date | disabledDate: (startDate$ | async)! : (endDate$ | async)! : (disabledDates$ | async)!,
                    today: date | todayDate,
                    before: date | afterCurrentDate: (selectedDate$ | async),
                    after: date | beforeCurrentDate: (selectedDate$ | async),
                    'current-month': date | currentMonth: (currentDate$ | async)
                }"
            >
                {{ date.getDate() }}
            </button>
        </div>
    </div>
</div>
