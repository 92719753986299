import { Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-form-input-password',
    styleUrls: ['./form-input-password.component.scss'],
    templateUrl: './form-input-password.component.html',
})
export class FormInputPasswordComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() iconName: string | undefined;
    @Input() placeholder: string | null = null;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() inputClasses = '';

    control!: FormControl<string | null>;

    type$ = new BehaviorSubject<'password' | 'text'>('password');

    ngOnInit() {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    get value() {
        return this.control.getRawValue();
    }

    togglePasswordVisibility() {
        const newType = this.type$.value === 'password' ? 'text' : 'password';

        this.type$.next(newType);
    }
}
