import { Injectable } from '@angular/core';
import { LocalStorage } from '@app/shared/classes';
import { environment } from '@env/environment';
import { BehaviorSubject, Subscription, map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserSwitchService {
    readonly customerId$ = new BehaviorSubject<null | string>(null);
    readonly enabled$ = this.customerId$.pipe(map((customerId) => customerId !== null));
    readonly disabled$ = this.customerId$.pipe(map((customerId) => customerId === null));

    private readonly subscriptions$ = new Subscription();

    set(id: string) {
        this.customerId$.next(id);
    }

    reset() {
        this.customerId$.next(null);
    }

    constructor() {
        const id = LocalStorage.read(environment.userSwitch.keys.localStorage);
        if (id !== null) {
            this.set(id);
        }
        this.subscriptions$.add(this.customerId$.pipe(tap((id) => LocalStorage.write(environment.userSwitch.keys.localStorage, id))).subscribe());
    }
}
