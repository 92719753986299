import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/icons';
import { FloatingLabelModule } from '../floating-label/floating-label.module';

import { FormQuantityInputComponent } from './components/form-quantity-input/form-quantity-input.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FormQuantityInputComponent],
    imports: [CommonModule, IconsModule, ReactiveFormsModule, FormsModule, FloatingLabelModule, TranslateModule],
    exports: [ReactiveFormsModule, FormQuantityInputComponent],
})
export class FormQuantityInputModule {}
