import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';
import * as XRegExp from 'xregexp';

export const WordsValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern = "^[\\p{L}\\s\\-']+$";
    if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
        return { [FormErrorTypes.WORDS]: true };
    }
    if (value.trim() === '' && value !== '') {
        return { [FormErrorTypes.TEXT]: true };
    }
    return null;
};
