import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-reset',
    styleUrls: ['./reset.component.scss'],
    templateUrl: './reset.component.html',
})
export class ResetComponent {
    @Output() resetEvent = new EventEmitter();

    onResetClick(event: Event) {
        event.preventDefault();
        this.resetEvent.next(true);
    }
}
