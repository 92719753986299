import { Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
})
export class FormCheckboxComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() placeholder: string | null = null;
    @Input() showErrors = true;

    control!: FormControl<boolean | null>;

    ngOnInit() {
        this.control = <FormControl<boolean | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    get value() {
        return this.control.getRawValue();
    }
}
