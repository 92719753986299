import { Component, inject } from '@angular/core';
import { UserSwitchService } from '@app/modules/user-switch';
import { WindowService } from '@app/shared/services';
import { menu } from '../../configs/menu';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    private readonly userSwitchService = inject(UserSwitchService);
    private readonly windowService = inject(WindowService);

    readonly menu = menu;

    xl$ = this.windowService.xl$;
    lg$ = this.windowService.lg$;
    md$ = this.windowService.md$;
    sm$ = this.windowService.sm$;
    xs$ = this.windowService.xs$;

    userSwitchEnabled$ = this.userSwitchService.enabled$;
}
