import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';~

// import { IconsModule } from './icons/icons.module';
// import { AppFormsModule } from './forms';
// import { AppTooltipModule } from './tooltip/tooltip.module';

// const MODULES = [AppFormsModule, IconsModule, AppTooltipModule];

// const COMPONENTS = [];
// const DIRECTIVES = [];

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    exports: [CommonModule, TranslateModule],
    declarations: [],
})
export class SharedModule {}
