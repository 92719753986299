import { Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, filter, map } from 'rxjs';

@Component({
    selector: 'app-picker',
    template: '',
})
export class BasePickerComponent implements OnDestroy {
    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() disabledDates: Date[] = [];

    currentDate$ = new BehaviorSubject<Date | null>(null);
    startDate$ = new BehaviorSubject<Date | null>(null);
    endDate$ = new BehaviorSubject<Date | null>(null);
    disabledDates$ = new BehaviorSubject<Date[]>([]);

    readonly subscriptions$ = new Subscription();

    calendar$ = this.currentDate$.pipe(
        filter((currentDate): currentDate is Date => currentDate !== null),
        map((currentDate) => {
            const calendar = [currentDate];
            const daysInMonth = new Date(calendar.at(0)!.getFullYear(), calendar.at(0)!.getMonth() + 1, 0).getDate();

            // fill calendar days before currentDate
            while (calendar[0].getDate() !== 1) {
                const date = new Date(calendar[0]);
                date.setDate(date.getDate() - 1);
                calendar.unshift(date);
            }

            while (calendar[0].getDay() !== 1) {
                const date = new Date(calendar[0]);
                date.setDate(date.getDate() - 1);
                calendar.unshift(date);
            }

            // fill calendar days after currentDate
            while (calendar[calendar.length - 1].getDate() !== daysInMonth) {
                const date = new Date(calendar[calendar.length - 1]);
                date.setDate(date.getDate() + 1);
                calendar.push(date);
            }

            while (calendar[calendar.length - 1].getUTCDay() !== 6) {
                const date = new Date(calendar[calendar.length - 1]);
                date.setDate(date.getDate() + 1);
                calendar.push(date);
            }

            return calendar;
        }),
    );

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onNextMonthClick(event: Event) {
        event.preventDefault();
        if (this.currentDate$.value !== null) {
            const date = new Date(this.currentDate$.value);
            const newDate = new Date(date.setMonth(date.getMonth() + 1));
            this.currentDate$.next(newDate);
        }
    }

    onPrevMonthClick(event: Event) {
        event.preventDefault();
        if (this.currentDate$.value !== null) {
            const date = new Date(this.currentDate$.value);
            const newDate = new Date(date.setMonth(date.getMonth() - 1));
            this.currentDate$.next(newDate);
        }
    }
}
