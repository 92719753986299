import { ShippingAddressDto } from './shipping-address.model.dto';

export class ShippingAddress {
    customerNo: string;
    code: string;
    name: string;
    city: string;
    countryRegionCode: string;
    postCode: string;
    address: string;
    address2: string;
    locationCode: string;
    email: string;
    shipmentMethodCode: string;
    systemId: string;

    constructor(shippingAddressDto: ShippingAddressDto) {
        this.customerNo = shippingAddressDto.customer_no;
        this.code = shippingAddressDto.code;
        this.name = `${shippingAddressDto.name ?? ''}${shippingAddressDto.name2 ?? ''}`;
        this.city = shippingAddressDto.city;
        this.countryRegionCode = shippingAddressDto.country_region_code;
        this.postCode = shippingAddressDto.post_code;
        this.address = shippingAddressDto.address;
        this.address2 = shippingAddressDto.address2;
        this.locationCode = shippingAddressDto.location_code;
        this.email = shippingAddressDto.e_mail;
        this.shipmentMethodCode = shippingAddressDto.shipment_method_code;
        this.systemId = shippingAddressDto.system_id;
    }
}
