import { Pipe, PipeTransform, inject } from '@angular/core';
import { RoleService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Pipe({
    name: 'productAmount$',
})
export class ProductAmountPipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);
    private readonly roleService = inject(RoleService);

    transform(value: number) {
        return this.roleService.isUser$.pipe(map((isUser) => (value > 500 && isUser ? this.translateService.instant('products.table.large-amount') : value)));
    }
}
