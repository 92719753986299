import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectBlocked, selectLoaded, selectSkipCreditLimitVerification, selectUser } from '../selectors/user.selector';
import { userActions } from '../actions/user.actions';
import { ChangePasswordValue, User } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    user$ = this.store.select(selectUser).pipe(filter((data): data is User => data !== null));

    blocked$ = this.store.select(selectBlocked);
    skipCreditLimitVerification$ = this.store.select(selectSkipCreditLimitVerification).pipe(filter((skip): skip is boolean => skip !== null));

    getUser(): void {
        this.store.dispatch(userActions.getUser());
    }

    changePassword(value: ChangePasswordValue): void {
        this.store.dispatch(userActions.changePassword({ value }));
    }

    clear(): void {
        this.store.dispatch(userActions.clear());
    }
}
