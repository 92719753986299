import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'disabledDate',
})
export class DisabledDatePipe implements PipeTransform {
    transform(date: Date, startDate: Date | null, endDate: Date | null, disabledDates: Date[]): boolean {
        return (
            (startDate && date < startDate) ||
            (endDate && date > endDate) ||
            disabledDates.findIndex((disableDate) => disableDate.getTime() === date.getTime()) !== -1
        );
    }
}
