import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';
import { WhiteSpaceValidator } from './white-space';

export const MustMatchValidator = (controlName: string, matchingControlName: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const firstControl = control.get(controlName);
        const secondControl = control.get(matchingControlName);

        if (firstControl === null || secondControl === null) return null;

        const v1: unknown = <string>firstControl.value;
        const v2: unknown = <string>secondControl.value;
        const firstWhiteSpaceError = WhiteSpaceValidator(firstControl);
        const secondWhiteSpaceError = WhiteSpaceValidator(secondControl);

        if (v1 === '' && v2 === '') {
            return null;
        }

        if (firstWhiteSpaceError !== null) {
            const firstErrors = firstControl.errors;
            firstControl.setErrors({ ...firstErrors, ...firstWhiteSpaceError });
            return { [FormErrorTypes.TEXT]: true };
        }

        if (secondWhiteSpaceError !== null) {
            const secondErrors = secondControl.errors;
            secondControl.setErrors({ ...secondErrors, ...secondWhiteSpaceError });
            return { [FormErrorTypes.TEXT]: true };
        }

        if (v1 !== v2) {
            const errors = firstControl?.errors;
            const errors2 = secondControl?.errors;
            firstControl.setErrors({ ...errors, [FormErrorTypes.MUST_MATCH]: true });
            secondControl.setErrors({ ...errors2, [FormErrorTypes.MUST_MATCH]: true });

            return { [FormErrorTypes.MUST_MATCH]: true };
        }

        firstControl.setErrors(null);
        secondControl.setErrors(null);

        return null;
    };
};
