<div [attr.data-test-id]="controlName">
    <div class="relative min-w-[16rem]">
        <input
            #formInput
            [value]="inputValue$ | async"
            type="text"
            class="custom-focus | h-10 md:h-12 rounded-eip-cta bg-white pl-5 pr-12 text-black w-full text-xs md:text-sm placeholder:text-gray-400 placeholder:opacity-100 border border-gray-200"
            [class]="class"
            (keyup.enter)="patchSearchValue()"
        />

        <button *ngIf="formInput.value" (click)="clearSearch()" type="button" class="absolute right-9 leading-4! top-1/2 -translate-y-1/2">
            <app-icon class="text-gray-600 hover:text-eip-orange transition-colors !leading-4 text-md" name="ic:round-close" />
        </button>

        <button (click)="patchSearchValue()" type="button" class="absolute right-4 top-1/2 -translate-y-1/2">
            <app-icon class="text-md text-gray-600 hover:text-eip-secondary transition-colors !leading-4" name="ic:round-search" />
        </button>

        <app-floating-label [placeholder]="placeholder ?? ''" [value]="formInput.value" [displayValue]="false" />
    </div>
</div>
