<div>
    <div class="relative text-xs md:text-sm">
        <input
            type="text"
            class="custom-focus | pr-5 peer h-10 w-full rounded-eip-cta border border-gray-200 hover:border-gray-500 | md:h-12"
            [formControl]="control"
            [ngClass]="[inputClasses, iconName ? 'pl-12' : 'pl-5']"
        />

        <app-floating-label [iconName]="iconName" [placeholder]="placeholder" [value]="value" [displayValue]="false"></app-floating-label>
    </div>

    <app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>
</div>
