import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/icons';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorModule } from '../form-error/form-error.module';
import { FloatingLabelModule } from '../floating-label/floating-label.module';

import { FormDropdownTinyComponent } from './components/form-dropdown-tiny/form-dropdown-tiny.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ListComponent } from './components/list/list.component';
import { SpinnerModule } from '@app/shared/components/spinner';

@NgModule({
    imports: [CommonModule, IconsModule, ReactiveFormsModule, FormsModule, TranslateModule, FormErrorModule, FloatingLabelModule, SpinnerModule],

    declarations: [FormDropdownTinyComponent, PreviewComponent, ListComponent],
    exports: [ReactiveFormsModule, FormDropdownTinyComponent],
})
export class FormDropdownTinyModule {}
