import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const WhiteSpaceValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';

    if (typeof value === 'string' && value.trim() === '' && value !== '') {
        return { [FormErrorTypes.TEXT]: true };
    }
    return null;
};
