import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-form-price-range',
    templateUrl: './form-price-range.component.html',
    styleUrls: ['./form-price-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPriceRangeComponent implements OnInit, OnDestroy {
    private readonly controlContainer = inject(ControlContainer);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input({ required: true }) placeholder!: string;
    @Input({ required: true }) controlName!: string;
    @Input() min = 0;
    @Input({ required: true }) max = 100;

    private readonly subscriptions$ = new Subscription();

    gap = 1;

    form!: FormGroup<{ min: FormControl; max: FormControl }>;

    get minValue() {
        return this.form.controls.min.value;
    }

    get maxValue() {
        return this.form.controls.max.value;
    }

    get left() {
        return `${(+this.form.controls.min.value / this.max) * 100}%`;
    }

    get width() {
        return ((this.maxValue - this.minValue) / this.max) * 100 + '%';
    }

    get valueLabel() {
        if (this.minValue !== this.min || this.maxValue !== this.max) {
            return this.minValue + ' - ' + this.maxValue;
        }

        return '';
    }

    ngOnInit() {
        this.form = (<FormGroup>this.controlContainer.control).controls[this.controlName] as FormGroup<{
            min: FormControl<number>;
            max: FormControl<number>;
        }>;
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    handleMinInput() {
        if (this.minValue < this.maxValue - 1) {
            return;
        }

        if (this.minValue === this.max || this.maxValue === this.max) {
            this.form.patchValue({ min: this.max - 1, max: this.max });
            this.changeDetectorRef.detectChanges();

            return;
        }

        this.form.patchValue({ max: this.minValue + 1 });
        this.changeDetectorRef.detectChanges();
    }

    handleMaxInput() {
        if (this.maxValue > this.minValue + 1) {
            return;
        }

        if (this.maxValue === this.min || this.minValue <= this.min) {
            this.form.patchValue({ max: 1, min: this.min });

            return;
        }

        this.form.patchValue({ min: this.maxValue - 1 });
    }

    handleMaxInputChange(e: Event) {
        e.preventDefault();
        const value = (e.target as HTMLInputElement).value;
        const valueAsNumber = Number(value);
        if (/^-?\d+$/.test(`${value}`) === false || valueAsNumber > this.max) {
            return this.setMaxValue(e, this.max);
        }

        if (valueAsNumber <= this.minValue) {
            return this.setMaxValue(e, this.minValue + 1);
        }

        this.setMaxValue(e, valueAsNumber);
    }

    handleMinInputChange(e: Event) {
        e.preventDefault();
        const value = (e.target as HTMLInputElement).value;
        const valueAsNumber = Number(value);

        if (/^-?\d+$/.test(`${value}`) === false || valueAsNumber < this.min) {
            return this.setMinValue(e, this.min);
        }

        if (valueAsNumber >= this.maxValue) {
            return this.setMinValue(e, this.maxValue - 1);
        }

        this.setMinValue(e, valueAsNumber);
    }

    private setMaxValue(e: Event, value: number) {
        (e.target as HTMLInputElement).value = value.toString();
        this.form.patchValue({ max: value });
    }

    private setMinValue(e: Event, value: number) {
        (e.target as HTMLInputElement).value = value.toString();
        this.form.patchValue({ min: value });
    }
}
