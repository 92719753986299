import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrorMsg } from '../../enums/form-error-msg.enum';

import * as XRegExp from 'xregexp';
import { Time } from '@app/shared/classes';

export const DateValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <Date>control.value || '';
    const pattern = /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/;
    if (typeof value === 'string' || (!XRegExp.test(Time.dateToString(value), XRegExp(pattern)) && Time.dateToString(value) !== '')) {
        return { [FormErrorMsg.INVALID_DATE]: true };
    }

    return null;
};
