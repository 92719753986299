import { Component, AfterViewInit, ChangeDetectorRef, ElementRef, QueryList, ViewChildren, inject } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import { Lang } from '../../lang.type';

import { Observable, delay, map, of, take } from 'rxjs';

@Component({
    selector: 'app-language-switch',
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements AfterViewInit {
    private readonly languageService = inject(LanguageService);
    private readonly cdr = inject(ChangeDetectorRef);

    @ViewChildren('langOpt') langOpt!: QueryList<ElementRef<HTMLButtonElement>>;
    readonly languages: { name: string; value: Lang }[] = [
        { name: 'EN', value: 'en-EN' },
        { name: 'PL', value: 'pl-PL' },
    ];

    language$ = this.languageService.language$;
    initialTransitionDelay$ = this.languageService.language$.pipe(take(1), delay(100));
    underlineLeftOffset$: Observable<number | null> = of(null);

    ngAfterViewInit() {
        this.underlineLeftOffset$ = this.language$.pipe(
            map((lang) => {
                const index = this.languages.findIndex((e) => e.value === lang);
                const el = this.langOpt?.get(index)?.nativeElement;
                return el ? el.offsetLeft : null;
            }),
        );
        this.cdr.detectChanges();
    }

    changeLang(lang: Lang) {
        this.languageService.set(lang);
    }
}
