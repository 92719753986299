import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    transform(value: number | null, priceType: 'brutto' | null = null): string {
        if (value === null) {
            return '- PLN';
        }
        const [intPart, floatPart] = Number(value / 100)
            .toFixed(2)
            .split('.');

        return `${this.addSpaceSeparators(intPart)},${floatPart} PLN${priceType === 'brutto' ? ' / Brutto' : ''}`;
    }

    private addSpaceSeparators(x: string) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
