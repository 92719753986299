import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DropdownService {
    open$ = new BehaviorSubject(false);

    setOpen(value: boolean) {
        this.open$.next(value);
    }
}
