import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-calendar',
    styleUrls: ['./form-calendar.component.scss'],
    templateUrl: './form-calendar.component.html',
})
export class FormCalendarComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() placeholder?: string;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() class = '';
    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() disabledDates: Date[] = [];

    control!: FormControl<Date | null>;

    ngOnInit() {
        this.control = <FormControl<Date | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }
}
