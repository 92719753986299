import { AfterViewInit, Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, debounceTime, fromEvent, Subscription, tap } from 'rxjs';

@Component({
    selector: 'app-form-search',
    styleUrls: ['./form-search.component.scss'],
    templateUrl: './form-search.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormSearchComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    private readonly controlContainer = inject(ControlContainer);

    @ViewChild('formInput') formInput!: ElementRef<HTMLInputElement>;

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) searchValue!: string;
    @Input() placeholder: string | null = null;
    @Input() class = '';

    control!: FormControl;

    private readonly subscriptions$ = new Subscription();
    inputValue$ = new BehaviorSubject('');

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }

    ngAfterViewInit() {
        this.subscriptions$.add(
            fromEvent(this.formInput.nativeElement, 'input')
                .pipe(
                    tap((event) => this.inputValue$.next((event.target as HTMLInputElement).value)),
                    debounceTime(1000),
                    tap(() => this.patchSearchValue()),
                )
                .subscribe(),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchValue'] !== undefined) {
            this.inputValue$.next(this.searchValue);
        }
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    patchSearchValue() {
        const inputValue = this.formInput.nativeElement.value;

        if (this.control.value !== inputValue) {
            this.control.patchValue(inputValue);
        }
    }

    clearSearch() {
        this.control.patchValue('');
        this.inputValue$.next('');
    }
}
