import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/icons';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormErrorModule } from '../form-error/form-error.module';
import { FloatingLabelModule } from '../floating-label/floating-label.module';

@NgModule({
    declarations: [FormTextareaComponent],
    imports: [CommonModule, IconsModule, ReactiveFormsModule, FormsModule, FormErrorModule, FloatingLabelModule],
    exports: [ReactiveFormsModule, FormTextareaComponent],
})
export class FormTextareaModule {}
