<div>
    <label [for]="controlName">
        <input type="checkbox" [id]="controlName" [formControl]="control" />

        <div class="custom-checkbox">
            <app-icon name="material-symbols:check-small" class="!leading-4 text-lg text-white"></app-icon>
        </div>
        <span *ngIf="placeholder" class="placeholder">{{ placeholder }}</span>
    </label>

    <app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>
</div>
