import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BasePickerComponent } from '../../../shared/form-calendar-picker/picker.component';
import { truncateTime } from '../../../shared/utils/truncate-time';

@Component({
    selector: 'app-picker',
    styleUrls: ['./picker.component.scss'],
    templateUrl: './picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerComponent extends BasePickerComponent implements OnChanges, OnDestroy {
    @Input() selectedDate: [Date, Date] | null = null;
    @Output() selectDateEvent = new EventEmitter<[Date, Date] | [Date, null] | null>();

    selectedDates$ = new BehaviorSubject<[Date, Date] | [Date, null] | null>(null);

    ngOnChanges(changes: SimpleChanges): void {
        Object.keys(changes).forEach((key) => {
            switch (key) {
                case 'selectedDate': {
                    const dates: [Date, Date] | null = changes[key].currentValue
                        ? [truncateTime(changes[key].currentValue[0]), truncateTime(changes[key].currentValue[1])]
                        : null;
                    this.selectedDates$.next(dates);
                    if (this.currentDate$.value === null) {
                        const date = dates !== null && dates[0] !== null ? dates[0] : truncateTime(new Date());
                        this.currentDate$.next(date);
                    }
                    break;
                }
                case 'startDate': {
                    const date: Date | null = changes[key].currentValue ? truncateTime(changes[key].currentValue) : null;
                    this.startDate$.next(date);
                    break;
                }
                case 'endDate': {
                    const date: Date | null = changes[key].currentValue ? truncateTime(changes[key].currentValue) : null;
                    this.endDate$.next(date);
                    break;
                }
                case 'disabledDates': {
                    const dates: Date[] = changes[key].currentValue.map((date: Date) => truncateTime(date));
                    this.disabledDates$.next(dates);
                    break;
                }
            }
        });
    }

    selectDate(event: Event, date: Date) {
        event.preventDefault();
        if (
            this.selectedDates$.value === null ||
            this.selectedDates$.value[0] === null ||
            (this.selectedDates$.value[0] !== null && this.selectedDates$.value[1] !== null)
        ) {
            this.selectedDates$.next([date, null]);
        } else if (this.selectedDates$.value[1] === null) {
            this.selectedDates$.next([this.selectedDates$.value[0], date]);
        }
        this.selectDateEvent.emit(this.selectedDates$.value);
    }
}
