import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryPipe } from './pipes/dictionary.pipe';

@NgModule({
    declarations: [DictionaryPipe],
    imports: [CommonModule],
    exports: [DictionaryPipe],
})
export class DictionaryPipeModule {}
