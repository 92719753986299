import { Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { Option } from '../../models/option.model';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    animations: [Grow],
})
export class ListComponent {
    @Input({ required: true }) options!: Option[];
    @Input({ required: true }) value!: string | null;
    @Output() selectEvent = new EventEmitter<string | null>();

    selectOption(event: Event, value: string | null) {
        event.preventDefault();
        this.selectEvent.next(value);
    }
    trackOptionBy: TrackByFunction<Option> = (_: number, option: Option) => option.value;
}
