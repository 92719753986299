<div class="flex align-center gap-4">
    <app-icon name="fa6-solid:language" class="text-md text-gray-700 leading-4"></app-icon>
    <div class="flex gap-4 h-full items-stretch text-sm relative">
        <div
            *ngIf="(underlineLeftOffset$ | async) !== null"
            class="absolute bg-gray-800 h-[2px] bottom-0 left-0 w-5"
            [ngClass]="{ 'transition-transform duration-[250ms]': initialTransitionDelay$ | async }"
            [ngStyle]="{ transform: 'translateX(' + (underlineLeftOffset$ | async) + 'px)' }"
        ></div>
        <button
            #langOpt
            *ngFor="let lang of languages"
            type="button"
            class="grid place-content-center border-b-2 border-transparent font-semibold w-5 transition-colors"
            [class]="(language$ | async) === lang.value ? ' text-gray-900' : 'text-gray-300'"
            (click)="changeLang(lang.value)"
        >
            {{ lang.name }}
        </button>
    </div>
</div>
