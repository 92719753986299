import { Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-textarea',
    styleUrls: ['./form-textarea.component.scss'],
    templateUrl: './form-textarea.component.html',
})
export class FormTextareaComponent implements OnInit {
    private controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label: string | null = null;
    @Input() placeholder: string | null = null;
    @Input() helptext: string | false = false;
    @Input() showErrors = true;
    @Input() class = '';

    control!: FormControl<string | null>;

    ngOnInit() {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
    }
}
