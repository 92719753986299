import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/icons';
import { FormErrorModule } from '../form-error/form-error.module';
import { TranslateModule } from '@ngx-translate/core';

import { DisabledDatePipe, TodayDatePipe, AfterCurrentPipe, BeforeCurrentPipe, CurrentMonthPipe } from './pipes';

import { PickerComponent } from './components/picker/picker.component';
import { PreviewComponent } from './components/preview/preview.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FormCalendarComponent } from './components/form-calendar/form-calendar.component';
import { FilterWrapperModule } from '@app/shared/components';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, IconsModule, FormErrorModule, FilterWrapperModule],
    declarations: [
        DisabledDatePipe,
        TodayDatePipe,
        AfterCurrentPipe,
        BeforeCurrentPipe,
        CurrentMonthPipe,
        FormCalendarComponent,
        CalendarComponent,
        PreviewComponent,
        PickerComponent,
    ],
    exports: [ReactiveFormsModule, FormCalendarComponent],
})
export class FormCalendarModule {}
