<div>
    <div class="relative text-xs md:text-sm">
        <input
            #formInput
            [type]="type$ | async"
            [formControl]="control"
            class="custom-focus | pr-12 peer w-full outline-none h-10 rounded-eip-cta border border-gray-200 hover:border-gray-500 | md:h-12"
            [class]="iconName ? 'pl-12' : 'pl-5'"
        />

        <button type="button" class="absolute right-2 top-1/2 -translate-y-1/2 p-2" (click)="togglePasswordVisibility()">
            <app-icon
                class="text-lg text-gray-400 !leading-4"
                [name]="(type$ | async) === 'password' ? 'clarity:eye-line' : 'clarity:eye-hide-line'"
            ></app-icon>
        </button>

        <app-floating-label [placeholder]="placeholder ?? ''" [value]="value" [displayValue]="false" [iconName]="iconName"></app-floating-label>
    </div>

    <app-form-error *ngIf="showErrors && control.touched" [errors]="control.errors"></app-form-error>
</div>
