import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, mergeMap } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../services/http.service';
import { LoadersFacade } from '@app/store/loader';

@Injectable()
export class UserEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUser),
            mergeMap(() => {
                this.loadersFacade.add('get-users');
                return this.httpService.getUser().pipe(
                    map((user) => userActions.getUserSuccess({ user })),
                    catchError(() => of(userActions.getUserError())),
                    finalize(() => this.loadersFacade.delete('get-users')),
                );
            }),
        );
    });

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.changePassword),
            mergeMap(({ value }) => {
                this.loadersFacade.add('change-password');
                return this.httpService.changePassword(value).pipe(
                    map(() => userActions.changePasswordSuccess()),
                    catchError(() => of(userActions.changePasswordError())),
                    finalize(() => this.loadersFacade.delete('change-password')),
                );
            }),
        );
    });
}
