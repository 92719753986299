import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/icons';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorModule } from '../form-error/form-error.module';

import { DisabledDatePipe, TodayDatePipe, AfterCurrentPipe, BeforeCurrentPipe, CurrentMonthPipe, FirstSelectedPipe, LastSelectedPipe } from './pipes';
import { CalendarComponent, FormDaterangeComponent, PickerComponent, PreviewComponent, ResetComponent } from './components';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, IconsModule, FormErrorModule],
    declarations: [
        DisabledDatePipe,
        TodayDatePipe,
        AfterCurrentPipe,
        BeforeCurrentPipe,
        CurrentMonthPipe,
        FirstSelectedPipe,
        LastSelectedPipe,
        FormDaterangeComponent,
        CalendarComponent,
        PreviewComponent,
        PickerComponent,
        ResetComponent,
    ],
    exports: [ReactiveFormsModule, FormDaterangeComponent],
})
export class FormDateRangeModule {}
