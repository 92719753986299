import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, switchMap, tap } from 'rxjs';
import { HttpService } from '../services/http.service';
import { productActions } from '../actions/product.actions';
import { LoadersFacade } from '@app/store/loader';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProductEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    getProductDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productActions.getProductDetails),
            switchMap(({ id }) => {
                this.loadersFacade.add('get-product');

                return this.httpService.getProductDetails(id).pipe(
                    map((product) => productActions.getProductDetailsSuccess({ product })),
                    catchError(() => of(productActions.getProductDetailsError())),
                    finalize(() => this.loadersFacade.delete('get-product')),
                );
            }),
        );
    });

    getProductDescription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productActions.getProductDescription),
            switchMap(({ productId }) => {
                return this.httpService.getProductDescription(productId).pipe(
                    map((description) => productActions.getProductDescriptionSuccess({ description })),
                    catchError(() => of(productActions.getProductDescriptionError())),
                );
            }),
        );
    });

    openPdf$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productActions.openProductCard),
            switchMap(({ productNo }) => {
                this.loadersFacade.add('open-document-pdf');

                return this.httpService.getProductCard(productNo).pipe(
                    map((blob) => productActions.openProductCardSuccess({ blob })),
                    catchError(() => of(productActions.openProductCardError())),
                    finalize(() => this.loadersFacade.delete('open-document-pdf')),
                );
            }),
        );
    });

    openProductCardSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(productActions.openProductCardSuccess),
                tap(({ blob }) => {
                    const url = URL.createObjectURL(blob);
                    window.open(url);
                }),
            );
        },
        { dispatch: false },
    );

    openProductCardError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(productActions.openProductCardError),
                tap(() => this.messageService.add(this.translateService.instant('products.open-card.error'), 'danger')),
            );
        },
        { dispatch: false },
    );
}
