import { Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-form-switch',
    templateUrl: './form-switch.component.html',
    styleUrls: ['./form-switch.component.scss'],
})
export class FormSwitchComponent implements OnInit {
    @Input({ required: true }) controlName!: string;
    @Input() yes: string = 'Yes';
    @Input() no: string = 'No';
    @Input() reversed = false;

    readonly id = uuidv4();
    private controlContainer = inject(ControlContainer);

    label$!: Observable<string>;
    control!: FormControl<string | null>;

    ngOnInit(): void {
        this.control = <FormControl<string | null>>(<FormGroup>this.controlContainer.control).controls[this.controlName];
        this.label$ = this.control.valueChanges.pipe(
            startWith(this.control.getRawValue()),
            map((value) => (value ? this.yes : this.no)),
        );
    }

    prevDefault(event: MouseEvent) {
        event.preventDefault();
    }
}
