import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-preview',
    styleUrls: ['./preview.component.scss'],
    templateUrl: './preview.component.html',
})
export class PreviewComponent {
    @Input({ required: true }) value: [Date, Date] | [Date, null] | null = null;
    @Input({ required: true }) open!: boolean;
    @Input() placeholder?: string;
}
