import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BehaviorSubject, delay, filter, skip, Subscription, tap } from 'rxjs';
import { truncateTime } from '../../../../forms/shared/utils/truncate-time';
import { Grow } from '@app/shared/animations';
import { onChangeCallback, onTouchCallback } from '@app/shared/forms/shared';

@Component({
    selector: 'app-daterange',
    styleUrls: ['./calendar.component.scss'],
    templateUrl: './calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
    ],
    animations: [Grow],
})
export class CalendarComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit {
    private cdr = inject(ChangeDetectorRef);
    @ViewChild('calendar') calendar!: ElementRef<HTMLDivElement>;

    @Input() label?: string;
    @Input() placeholder?: string;
    @Input() startDate: Date | null = null;
    @Input() endDate: Date | null = null;
    @Input() disabledDates: Date[] = [];

    open$ = new BehaviorSubject<boolean>(true);

    selectedValue$ = new BehaviorSubject<[Date, Date] | [Date, null] | null>(null);
    private _value: [Date, Date] | null = null;
    private subscriptions$ = new Subscription();

    get value(): [Date, Date] | null {
        return this._value;
    }

    set value(value: [Date, Date] | null) {
        this._value = value !== null ? [truncateTime(value[0]), truncateTime(value[1])] : null;
    }

    ngOnInit() {
        this.subscriptions$.add(
            this.selectedValue$
                .pipe(
                    // skip first on init event
                    skip(1),
                    // allow only valid [Date, Date] values
                    filter((selectedValue): selectedValue is [Date, Date] | null => selectedValue === null || selectedValue[1] !== null),
                    // set selected value
                    tap((selectedValue) => (this.value = selectedValue)),
                    // delay for proper beforeDestroy animation
                    delay(1),
                    // close (destroy)
                    tap(() => this.open$.next(false)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    writeValue(value: [Date, Date] | null): void {
        this.selectedValue$.next(value);
        this.value = value;
        this.cdr.markForCheck();
    }

    registerOnChange(fn: onChangeCallback<[Date, Date] | null>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: onTouchCallback): void {
        this.onTouch = fn;
    }

    onChange: onChangeCallback<[Date, Date] | null> = (): void => {};
    onTouch: onTouchCallback = (): void => {};

    onFocusIn() {
        this.open$.next(true);
    }

    onFocusOut(event: FocusEvent) {
        const target = <HTMLElement>event.relatedTarget;
        if (this.calendar.nativeElement.contains(target) === false) {
            this.open$.next(false);
            this.selectedValue$.next(this.value);
        }
    }

    selectDateEvent(value: [Date, Date] | [Date, null] | null) {
        const dates = this.sortDate(value);
        this.selectedValue$.next(dates);
        if (dates === null || dates[1] !== null) {
            this.onChange(dates);
            this.onTouch();
        }
    }

    resetEvent() {
        this.selectedValue$.next(null);
    }

    validate(control: AbstractControl<[Date, Date] | [Date, null] | null>): ValidationErrors | null {
        const value = control.value;
        if (value !== null && value[1] !== null) {
            const selectedStartDate = truncateTime(value[0]);
            const selectedEndDate = truncateTime(value[1]);
            const dates = this.disabledDates.map((date) => truncateTime(date));
            const isInDisabled =
                dates.findIndex((disableDate) => {
                    return disableDate.getTime() === selectedStartDate.getTime() || disableDate.getTime() === selectedEndDate.getTime();
                }) !== -1;

            const isBefore = this.startDate ? truncateTime(control.getRawValue()) < selectedStartDate : false;
            const isAfter = this.endDate ? truncateTime(control.getRawValue()) > selectedEndDate : false;

            if (isInDisabled || isBefore || isAfter) {
                return { 'out-of-range': true };
            }
        }
        return null;
    }

    private sortDate(selectedDates: [Date, Date] | [Date, null] | null) {
        if (selectedDates !== null && selectedDates[1] !== null && selectedDates[1].getTime() < selectedDates[0].getTime()) {
            return <[Date, Date]>[selectedDates[1], selectedDates[0]];
        }

        return selectedDates;
    }
}
